<template>
	<div class="Dedication">
		<w-navTab titleText="奉献榜"></w-navTab>
		<scroll-tab class="head-tab" :scroll-x="true" :menu="navList" :active="active" field="name" @click="onClick"></scroll-tab>
		<div class="container-body">
			<Society v-if="active == 0"></Society>
			<Personal v-if="active == 1"></Personal>
			<Volunteer v-if="active == 2"></Volunteer>
			<Enterprise v-if="active == 3"></Enterprise>
			<NetAdviser v-if="active == 4"></NetAdviser>
			<Medium v-if="active == 5"></Medium>
		</div>
	</div>
</template>

<script>
import ScrollTab from '@/components/ScrollTab';
import Society from '@/views/rank/components/Society';
import Personal from '@/views/rank/components/Personal';
import Volunteer from '@/views/rank/components/Volunteer';
import Enterprise from '@/views/rank/components/Enterprise';
import NetAdviser from '@/views/rank/components/NetAdviser';
import Medium from '@/views/rank/components/Medium';

	export default{
		name:'Dedication',
		data() {
			return{
				active: 0,
				navList: [{id:1,name:'协会爱心榜'},{id:2,name:'个人爱心榜'},{id:3,name:'志愿者之星'},{id:4,name:'企业爱心榜'},{id:5,name:'网络达人榜'},{id:6,name:'媒体达人榜'}],
			}
		},
		created() {
			
		},
		methods:{
			onClick(index) {
				this.active = index;
			},
		},
		components:{
			ScrollTab,
			Society,
			Personal,
			Volunteer,
			Enterprise,
			NetAdviser,
			Medium
		}
	}
</script>

<style scoped lang="less">
	.Dedication{
		.head-tab {
			height: 29px;
			padding: 12px 0 6px;
			margin-bottom: 10px;
			margin: 0 10px;
			border-bottom: 1px solid #DDD;
			/deep/.scroll-tab-item {
				padding-top: 0;
				font-size: 18px;
				line-height: 27px;
				padding-bottom: 2px;
		
				&.scroll-tab-item-active:after {
					bottom: -6px;
					width: 85px;
				}
			}
		}
		.container-body{
			
		}
	}
</style>