<template>
	<div class="Medium">
		<div class="container">
			<VantList class="list-view" :loading="loading" :finished="finished" :is-empty="isEmpty" @onLoad="onLoad">
				<div class="head">
					<div class="head-nav">
						<div :class="[{ color: module == 1 }]" @click="onChange(1)">当日排名</div>
						<div :class="[{ color: module == 2 }]" @click="onChange(2)">当月排名</div>
						<div :class="[{ color: module == 3 }]" @click="onChange(3)">年度排名</div>
						<div :class="[{ color: module == 4 }]" @click="onChange(4)">去年排名</div>
					</div>
					<div class="head-column">
						<div class="head-column-left">
							<div class="head-column-text">排行</div>
							<div class="head-column-text">姓名</div>
						</div>
							<div class="head-column-text">文章数量</div>
					</div>
					<div class="middle" v-for="(tp,index) in rankList" >
						<div class="middle-item">
							<div class="item-top">
								<div class="item-top-left">
									<div class="item-top-left-icon" v-if="index == 0"><img src="@/assets/img/icon87.png" alt=""></div>
									<div class="item-top-left-icon" v-if="index == 1"><img src="@/assets/img/icon88.png" alt=""></div>
									<div class="item-top-left-icon" v-if="index == 2"><img src="@/assets/img/icon89.png" alt=""></div>
									<div class="item-top-left-number" v-if="index > 2">{{ index + 1 }}</div>
									<div class="item-top-left-box">
										<div class="title">{{ tp.title }}</div>
									</div>
								</div>
								<div class="item-top-right">{{ tp.total ? tp.total : 0 }}</div>
							</div>
						</div>
					</div>

				</div>
			</VantList>
		</div>
	</div>
</template>

<script>
	import messageApi from '@/api/message';
	import VantList from '@/components/VantList';
	// 媒体达人榜
	export default {
		name: 'Medium',
		data() {
			return {
				loading: true,
				finished: false,
				isEmpty: false,
				module: 1,
				rankList: [],
				group: {
					page: 1,
					limit: 20
				},
			};
		},
		created() {
			this.onLoad();
		},
		methods: {
			onLoad() {
				// this.loading = true;

				// let params = {
				// 	type: this.type,
				// 	order: this.module,
				// 	page: this.group.page,
				// 	limit: this.group.limit
				// };
				this.loading = false;
				this.isEmpty = true;
				// messageApi.districtLoveRank(params)
				// 	.then(result => {
				// 		this.group.page++;
				// 		this.rankList = this.rankList.concat(result.data.list);
				// 		this.loading = false;
				// 		this.loadingPage = false;
				// 		if (this.rankList.length <= 0) {
				// 			this.isEmpty = true;
				// 		}

				// 		if (this.rankList.length >= result.data.total) {
				// 			this.finished = true;
				// 		}
				// 	})
				// 	.catch(error => {});
			},
			onChange(module) {
				this.module = module;
				// this.group.page = 1,
				// this.finished = false;
				// this.isEmpty = false;
				// this.rankList = []
				// this.onLoad();
			}
		},
		components: {
			VantList
		}
	}
</script>

<style scoped lang="less">
	.Medium {
		.container {
			padding: 0 10px;
			box-sizing: border-box;

			.list-view {
				height: 100vh;
			}

			.nav-top {
				padding-left: 10px;
			}

			.head {
				background: #fff;
				border-radius: 15px;
				padding: 10px;
				padding-bottom: 0;
				margin-top: 12px;

				.head-nav {
					display: flex;
					justify-content: space-between;
					align-items: center;
					padding: 0 10px;
					// border-bottom: 1px solid #ddd;

					div {
						font-size: 14px;
						line-height: 21px;
						color: #000;
						padding-bottom: 12px;
						margin-bottom: -1px;
					}
				}

				.color {
					color: #3377FF !important;
				}
				.head-column{
					display: flex;
					justify-content: space-between;
					align-items: center;
					padding-bottom: 10px;
					border-bottom: 1px solid #F5F6F8;
					margin-top: 6px;
					.head-column-left{
						display: flex;
						align-items: center;
					}
					.head-column-text:first-child{
						margin-right: 36px;
					}
					.head-column-text{
						font-weight: bold;
						font-size: 14px;
						line-height: 20px;
						color: #777B81;
					}
				}
				.middle:last-child {
					border: 0;
				}

				.middle {
					margin-top: 10px;
					padding: 0 10px;
					background-color: #fff;
					border-bottom: 1px solid #f2f2f2;

					.middle-item {
						.item-top {
							display: flex;
							justify-content: space-between;
							align-items: center;
							padding-bottom: 10px;

							.item-top-left {
								display: flex;
								justify-content: flex-start;
								align-items: center;
								.item-top-left-icon{
									width: 25px;
									height: 32px;
									margin-right: 27px;
									img{
										width: 100%;
										height: 100%;
										object-fit: cover;
									}
								}
								.item-top-left-number {
									font-weight: bold;
									font-size: 14px;
									line-height: 16px;
									color: #3377FF;
									margin-right: 27px;
									text-align: center;
									background: #F5F6F8;
									border-radius: 100px;
									padding: 4px 8px;
								}

								.item-top-left-box {
									.title {
										font-size: 17px;
										line-height: 24px;
										color: #1C1D1D;
									}
								}
							}

							.item-top-right {
								font-size: 15px;
								line-height: 22px;
								color: #3377FF;
							}
						}
					}
				}
			}
		}
	}
</style>
