import Config from "@/vendor/config"
import Request from "@/api/request.js"

// 用户信息
const district = (params) => {
	return Request.setBaseUrl(Config.apiUrl).request(Request.post, 'api/district/rank', params)
}

// 个人爱心榜
const rankPersonal = (params) => {
	return Request.setBaseUrl(Config.apiUrl).request(Request.post, 'api/rank/personal', params)
}

// 志愿者之心
const rankVolunteer = (params) => {
	return Request.setBaseUrl(Config.apiUrl).request(Request.post, 'api/rank/volunteer', params)
}

// 企业爱心榜
const rankCompany = (params) => {
	return Request.setBaseUrl(Config.apiUrl).request(Request.post, 'api/rank/company', params)
}


// 协会排行
const districtInfo = (params) => {
	return Request.setBaseUrl(Config.apiUrl).request(Request.post, 'api/district/info/rank', params)
}

export default {
	district,
	rankPersonal,
	rankVolunteer,
	rankCompany,
	districtInfo
}
