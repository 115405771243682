<template>
	<div class="Society">
		<div class="container">
			<div class="header-top">
				<div :class="['header-top-item', {'item-active': navIndex == index}]" v-for="(item,index) in navList"
					@click="onClickNav(index)">
					{{item.title}}
				</div>
			</div>

			<div class="header-title">
				（<span>说明：</span>{{titleText()}}）
			</div>

			<div class="head-nav" v-if="navIndex > 0">
				<div :class="[{ color: active == 1 }]" @click="onChange(1)">当日排名</div>
				<div :class="[{ color: active == 2 }]" @click="onChange(2)">当月排名</div>
				<div :class="[{ color: active == 3 }]" @click="onChange(3)">年度排名</div>
				<div :class="[{ color: active == 4 }]" @click="onChange(4)">去年排名</div>
			</div>

			<div class="head">
				<div class="head-item m-left" v-for="(item, key) in list" v-if="key == 1">
					<div class="head-item-picture item-picture"><img src="@/assets/img/icon208.png" alt="" /></div>
					<div class="picture-middle-two"><img :src="item.logo" alt="" /></div>
					<div class="head-item-title">{{ item.name }}</div>
					<div class="text" v-if="navIndex != 2">{{ getTime(item.tate) }}{{navIndex == 2?'小时':'%'}}</div>
					<div class="text" v-if="navIndex == 2">{{ item.tate }}{{navIndex == 2?'小时':'%'}}</div>
					<div class="head-item-text">
						<div class="text" v-if="navIndex == 0">{{item.population}}</div>
						<div class="text" v-if="navIndex == 1">{{item.user_total}}</div>
						<div class="text" v-if="navIndex == 2">{{item.service_time_total}}小时</div>
					</div>
					<div class="head-item-text">
						<div class="text">{{item.user_num}}</div>
					</div>
				</div>
				<div class="head-item" v-for="(item, key) in list" v-if="key == 0">
					<div class="head-item-picture"><img src="@/assets/img/icon207.png" alt="" /></div>
					<div class="picture-middle-one"><img :src="item.logo" alt="" /></div>
					<div class="head-item-title">{{ item.name }}</div>
					<div class="text" v-if="navIndex != 2">{{statusText()}}：{{ getTime(item.tate) }}{{navIndex == 2?'小时':'%'}}</div>
					<div class="text" v-if="navIndex == 2">{{statusText()}}：{{ item.tate }}{{navIndex == 2?'小时':'%'}}</div>
					<div class="head-item-text">
						<div class="text" v-if="navIndex == 0">{{totalText()}}：{{item.population}}</div>
						<div class="text" v-if="navIndex == 1">{{totalText()}}：{{item.user_total}}</div>
						<div class="text" v-if="navIndex == 2">{{totalText()}}：{{item.service_time_total}}小时</div>
					</div>
					<div class="head-item-text">
						<div class="text">{{singleText()}}：{{item.user_num}}</div>
					</div>
				</div>
				<div class="head-item m-right" v-for="(item, key) in list" v-if="key == 2">
					<div class="head-item-picture item-picture"><img src="@/assets/img/icon209.png" alt="" /></div>
					<div class="picture-middle-two"><img :src="item.logo" alt="" /></div>
					<div class="head-item-title">{{ item.name }}</div>
					<div class="text" v-if="navIndex != 2">{{ getTime(item.tate) }}{{navIndex == 2?'小时':'%'}}</div>
					<div class="text" v-if="navIndex == 2">{{ item.tate }}{{navIndex == 2?'小时':'%'}}</div>
					<div class="head-item-text">
						<div class="text" v-if="navIndex == 0">{{item.population}}</div>
						<div class="text" v-if="navIndex == 1">{{item.user_total}}</div>
						<div class="text" v-if="navIndex == 2">{{item.service_time_total}}小时</div>
					</div>
					<div class="head-item-text">
						<div class="text">{{item.user_num}}</div>
					</div>
				</div>
			</div>

			<div class="middle">
				<div class="middle-item" v-for="(item, key) in list" v-if="key > 2">
					<div class="item-top">
						<div class="item-top-left">
							<div class="item-top-left-picture"><img :src="item.logo" alt="" /></div>
							<div class="item-top-left-title">{{ item.name }}</div>
						</div>
						<div class="item-top-right" v-if="navIndex != 2">{{statusText()}}{{getTime(item.tate)}}{{navIndex == 2?'小时':'%'}}</div>
						<div class="item-top-right" v-if="navIndex == 2">{{statusText()}}{{item.tate}}{{navIndex == 2?'小时':'%'}}</div>
					</div>
					<div class="item-bottom">
						<div class="item-bottom-title">{{ key > 8 ? '' : '0' }}{{ key + 1 }}</div>
						<div class="item-bottom-text">
							<div class="text">{{singleText()}}：{{item.user_num}}</div>
						</div>
						<div class="item-bottom-text">
							<div class="text" v-if="navIndex == 0">{{totalText()}}：{{item.population}}</div>
							<div class="text" v-if="navIndex == 1">{{totalText()}}：{{item.user_total}}</div>
							<div class="text" v-if="navIndex == 2">{{totalText()}}：{{item.service_time_total}}小时</div>
						</div>
					</div>
				</div>
			</div>

		</div>
	</div>
</template>

<script>
	// 协会爱心榜
	import Rank from '@/api/rank.js';
	export default {
		name: 'Society',
		data() {
			return {
				navIndex: 0,
				navList: [{
					id: 1,
					title: '参与率'
				}, {
					id: 2,
					title: '出勤率'
				}, {
					id: 3,
					title: '服务时长'
				}],
				active: 1,
				list: '',
			}
		},
		created() {
			this.onRank();
		},
		methods: {
			getTime(value){
				return Number(value).toFixed(2);
			},
			onRank() {
				let params = {
					order: this.active,
					type: this.navList[this.navIndex].id
				};
				Rank.districtInfo(params).then(res => {
					this.list = res.data;
				});
			},
			onChange(active) {
				this.active = active;
				this.list = []
				this.onRank();
			},
			onClickNav(index) {
				this.navIndex = index
				this.list = []
				this.onRank();
			},
			statusText() {
				let text = ['参与率', '出勤率', '人均']
				return text[this.navIndex]
			},
			titleText() {
				let text = ['参与率=注册人数/当地常住人口', '出勤率=参与活动人数/注册人数', '服务时长=服务总时长/参与活动人数']
				return text[this.navIndex]
			},
			totalText() {
				let text = ['户籍人数', '总人数', '总时长']
				return text[this.navIndex]
			},
			singleText() {
				let text = ['参与人数', '活跃人数', '活跃人数']
				return text[this.navIndex]
			},
			getMath(min) {
				let time = min / 60
				return time.toFixed(2);
			}
		},
	}
</script>

<style scoped lang="less">
	.Society {
		.container {
			padding: 0 10px 10px 10px;
			box-sizing: border-box;

			.header-top {
				width: 242px;
				display: flex;
				justify-content: space-between;
				margin: 16px auto 8px;
				border-radius: 29px;
				background: #FFF;
				box-sizing: border-box;

				.header-top-item {
					padding: 4px 16px;
					color: #000;
					font-size: 14px;
					box-sizing: border-box;

					&.item-active {
						color: #FFF;
						border-radius: 32px;
						background: var(--v-201, #37F);
					}
				}
			}

			.header-title {
				margin-bottom: 11px;
				color: #999;
				text-align: center;
				font-size: 14px;
				line-height: 21px;
				span{
					color: #F3B100;
				}
			}

			.picture {
				width: 18px;
				min-width: 18px;
				height: 18px;

				img {
					width: 100%;
					height: 100%;
					object-fit: cover;
				}
			}

			.text {
				font-size: 14px;
				line-height: 20px;
				color: #666;
			}

			.head-nav {
				display: flex;
				justify-content: space-between;
				align-items: center;
				padding: 0 10px;
				// border-bottom: 1px solid #ddd;

				div {
					font-size: 14px;
					line-height: 21px;
					color: #000;
					padding-bottom: 12px;
					margin-bottom: -1px;
				}
			}

			.color {
				font-weight: bold;
				color: #3377ff !important;
				// border-bottom: 2px solid #3377ff;
			}

			.head {
				display: flex;
				justify-content: space-between;

				.head-item {
					padding: 9px 0;
					width: 138px;
					background-color: #fff;
					text-align: center;
					border-radius: 20px 20px 0px 0px;
					position: relative;
					box-sizing: border-box;

					.head-item-picture {
						position: relative;
						z-index: 10;
						width: 115px;
						min-width: 115px;
						height: 115px;
						margin: 0 auto;

						img {
							width: 100%;
							height: 100%;
							object-fit: cover;
						}
					}

					.picture-middle-one {
						position: absolute;
						z-index: 5;
						top: 20px;
						left: 0;
						width: 100px;
						min-width: 100px;
						height: 100px;
						margin-left: 50%;
						transform: translateX(-50%);

						img {
							width: 100%;
							height: 100%;
							border-radius: 50%;
							object-fit: cover;
						}
					}

					.picture-middle-two {
						position: absolute;
						z-index: 5;
						top: 17px;
						left: 0;
						width: 74px;
						min-width: 74px;
						height: 74px;
						margin-left: 50%;
						transform: translateX(-50%);

						img {
							width: 100%;
							height: 100%;
							border-radius: 50%;
							object-fit: cover;
						}
					}

					.item-picture {
						width: 85px;
						min-width: 85px;
						height: 85px;
					}

					.head-item-title {
						font-weight: bold;
						font-size: 18px;
						line-height: 26px;
						color: #333;
						margin-top: 5px;
					}

					.head-item-text {
						display: flex;
						align-items: center;
						justify-content: center;
					}
				}

				.m-left {
					width: 103px !important;
					margin-top: 50px;
					border-radius: 15px 15px 0px 20px;
				}

				.m-right {
					width: 103px !important;
					margin-top: 50px;
					border-radius: 15px 15px 20px 0;
				}
			}

			.middle {
				.middle-item {
					margin-top: 10px;
					padding: 15px 10px;
					border-radius: 6px;
					background-color: #fff;

					.item-top {
						display: flex;
						justify-content: space-between;
						align-items: center;
						padding-bottom: 10px;
						border-bottom: 1px solid #f2f2f2;

						.item-top-left {
							display: flex;
							justify-content: flex-start;
							align-items: center;

							.item-top-left-picture {
								width: 32px;
								min-width: 32px;
								height: 32px;
								margin-right: 5px;

								img {
									width: 100%;
									height: 100%;
									border-radius: 50%;
									object-fit: cover;
								}
							}

							.item-top-left-title {
								width: 170px;
								font-weight: bold;
								font-size: 16px;
								line-height: 23px;
								color: #333;
								white-space: nowrap;
								overflow: hidden;
								text-overflow: ellipsis;
							}
						}

						.item-top-right {
							font-weight: bold;
							font-size: 16px;
							line-height: 23px;
							color: #ff6969;
						}
					}

					.item-bottom {
						margin-top: 10px;
						display: flex;
						justify-content: space-between;
						align-items: center;

						.item-bottom-title {
							font-weight: bold;
							font-size: 18px;
							line-height: 20px;
							color: #3377ff;
						}

						.item-bottom-text {
							display: flex;
							justify-content: flex-start;
							align-items: center;
						}
					}
				}
			}
		}
	}
</style>