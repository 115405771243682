<template>
	<div class="Volunteer">
		<div class="container">
			<VantList class="list-view" :loading="loading" :finished="finished" :is-empty="isEmpty" @onLoad="onLoad">
			<div class="container-item" v-for="(item,index) in rankList">
				<div class="item-top">
					<img src="@/assets/img/icon90.png" alt="">
					<div class="item-top-title">
						{{item.group_name}}
					</div>
				</div>
				<div class="item-body">
					<div class="body-box box-conner">
						<div class="body-picture">
							<img :src="item.avatar" alt="">
						</div>
						<div class="body-box">
							<div class="body-box-name">{{item.name}}</div>
							<div class="body-box-grade">{{item.level_text}}</div>
						</div>
					</div>
					<div class="body-box box-center">
						<div class="body-box-text">{{item.service_count}}次</div>
						<div class="body-box-text">{{item.service_time}}小时</div>
					</div>
					<div class="body-box box-conner">
						<div class="body-box-icon">
							<img src="@/assets/img/icon85.png" alt="">
						</div>
						<div class="body-box-number">{{item.love_value}}</div>
					</div>
				</div>
			</div>
			</VantList>
		</div>
	</div>
</template>

<script>
	// 志愿者之星
	import Rank from '@/api/rank';
	import VantList from '@/components/VantList';
	export default{
		name:'Volunteer',
		data() {
			return{
				rankList:[],
				loading: true,
				finished: false,
				isEmpty: false,
				page:1,
				limit:10,
			}
		},
		created() {
			this.onLoad()
		},
		methods:{
			onLoad(){
				let params = {
					page: this.page,
					limit: this.limit
				}
				Rank.rankVolunteer(params)
					.then(result => {
						this.page++;
						this.rankList = this.rankList.concat(result.data);
						this.loading = false;
						
						if (this.rankList.length >= result.data.length) {
							this.finished = true;
						}
						
						if (this.rankList.length <= 0) {
							this.isEmpty = true;
						}
					})
					.catch(error => {});
			}
		},
		components: {
			VantList
		}
	}
</script>

<style scoped lang="less">
	.Volunteer{
		.container{
			padding: 10px;
			.container-item:first-child{
				margin-top: 0;
			}
			.container-item{
				padding: 12px 0;
				background: #FFF;
				border-radius: 15px;
				margin-top: 8px;
				box-sizing: border-box;
				.item-top{
					width: 184px;
					height: 43px;
					margin: 0 auto;
					margin-bottom: 14px;
					position: relative;
					img{
						display: block;
						width: 100%;
						height: 100%;
						object-fit: cover;
					}
					.item-top-title{
						position: absolute;
						left: 50%;
						top: 12px;
						flex-wrap: nowrap;
						width: 130px;
						min-width: 120px;
						transform: translateX(-50%);
						font-weight: 700;
						font-size: 20px;
						line-height: 30px;
						text-align: center;
						background: linear-gradient(180deg, #FAC278 0%, #8E6847 100%);
						-webkit-background-clip: text;
						-webkit-text-fill-color: transparent;
						background-clip: text;
						text-fill-color: transparent;
						text-shadow: 1px 1px 0px rgba(255, 227, 147, 0.4);
					}
				}
				.item-body{
					display: flex;
					justify-content: space-between;
					align-items: center;
					padding: 0 22px;
					.body-box{
						.body-picture{
							width: 40px;
							height: 40px;
							margin-right: 10px;
							border-radius: 50%;
							img{
								width: 100%;
								height: 100%;
								border-radius: 50%;
								object-fit: cover;
							}
						}
						.body-box-name{
							font-weight: bold;
							font-size: 16px;
							text-align: center;
							color: #222;
						}
						.body-box-grade{
							font-size: 14px;
							color: #777;
						}
						.body-box-text{
							font-size: 14px;
							text-align: center;
							color: #44484E;
						}
						.body-box-icon{
							width: 24px;
							height: 24px;
							margin-right: 4px;
							img{
								width: 100%;
								height: 100%;
								object-fit: cover;
							}
						}
						.body-box-number{
							font-weight: bold;
							font-size: 24px;
							line-height: 36px;
							color: #FF6969;
						}
					}
					.box-conner{
						display: flex;
						align-items: center;
					}
				}
			}
		}
	}
</style>