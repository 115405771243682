<template>
	<div class="Enterprise">
		<div class="container">
			<w-list class="list-view" :loading="loading" :finished="finished" :is-empty="isEmpty" @onLoad="onLoad">
				<div class="middle-item" v-for="item in companyList">
					<div class="middle-item-left"><img :src="item.image" alt="" /></div>
					<div class="middle-item-right">
						<div class="item-right-title">{{ item.name }}</div>
						<div class="item-right-box">
							<div class="item-right-box-picture">
								<img src="@/assets/img/icon203.png" alt="" />
							</div>
							<div class="item-right-box-text">积分：{{ item.integral }}</div>
						</div>
						<div class="item-right-box">
							<div class="item-right-box-picture">
								<img src="@/assets/img/icon202.png" alt="" />
							</div>
							<div class="item-right-box-text">{{ item.address }}</div>
						</div>
					</div>
				</div>
			</w-list>
		</div>
	</div>
</template>

<script>
	import WList from '@/components/List';
	import Rank from '@/api/rank';
	export default {
		// 企业爱心榜
		name: 'Enterprise',
		data() {
			return {
				active: 0,
				navList: [{
					id: 0,
					title: '全部'
				}],
				loading: true,
				finished: false,
				isEmpty: false,
				companyList: [],
				loadingPage: true,
				paramsPage: 1,
				paramsLimit: 10,
				paramsGroupId: 0
			};
		},
		created() {
			this.onLoad();
		},
		methods: {
			onLoad() {
				this.loading = true;

				let params = {
					page: this.paramsPage,
					limit: this.paramsLimit,
					group_id: this.paramsGroupId
				};

				Rank.rankCompany(params)
					.then(result => {
						this.paramsPage++;
						this.companyList = this.companyList.concat(result.data.list);

						this.loading = false;

						if (this.companyList.length <= 0) {
							this.isEmpty = true;
						}

						if (this.companyList.length >= result.data.total) {
							this.finished = true;
						}
					})
					.catch(error => {
						VantVendor.Toast.fail(error.msg);
					});
			}
		},
		components: {
			WList
		}
	}
</script>

<style scoped lang="less">
	.Enterprise {
		.container {
			padding: 0 10px;

			.middle-item {
				background-color: #fff;
				border-radius: 6px;
				overflow: hidden;
				margin-top: 10px;
				padding: 10px;
				box-sizing: border-box;
				display: flex;
				justify-content: flex-start;
				align-items: center;

				.middle-item-left {
					width: 132px;
					min-width: 132px;
					height: 90px;

					img {
						width: 100%;
						height: 100%;
						border-radius: 6px;
						object-fit: cover;
					}
				}

				.middle-item-right {
					padding-left: 11px;

					.item-right-title {
						width: 190px;
						min-width: 190px;
						font-size: 18px;
						line-height: 20px;
						color: #333;
						white-space: nowrap;
						overflow: hidden;
						text-overflow: ellipsis;
					}

					.item-right-box {
						margin-top: 5px;
						display: flex;
						justify-content: flex-start;

						.item-right-box-picture {
							width: 16px;
							min-width: 16px;
							height: 16px;
							margin-top: 2px;
							margin-right: 4px;

							img {
								width: 100%;
								height: 100%;
								object-fit: cover;
							}
						}

						.item-right-box-text {
							font-size: 14px;
							line-height: 20px;
							color: #666;
							display: -webkit-box;
							-webkit-box-orient: vertical;
							-webkit-line-clamp: 2;
							overflow: hidden;
						}
					}
				}
			}
		}

	}
</style>
